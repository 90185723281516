import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Constructor from "../components/constructor";
import ClientOnly from "../clientOnly";

function Page({ data: { prismicPage }, pageContext: { lang, currentUrl } }) {
    const currentLang = lang?.split('-')[0] || 'en';
  return (
    <>
      <Seo
        title={prismicPage.data.seo_title}
        description={prismicPage.data.seo_description}
        lang={currentLang}
        currentUrl={currentUrl}
      />

      <ClientOnly>
        <Layout>
          <Constructor slices={prismicPage.data.body} pageName={prismicPage.data.name} />
        </Layout>
      </ClientOnly>
    </>
  );
}

/**
 * Шаблон страниц
 * @module src/templates/page
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 */
export default Page;

export const query = graphql`
  query getPageData($uid: String!, $lang: String!) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        name
        seo_title
        seo_description
        body {
          ... on PrismicPageDataBodyRegistrationAndLogin {
            id
            slice_label
            slice_type
            primary {
              button_login_text
              button_open_trading_account
              button_registration_text
              button_try_demo_text
              button_sms_title
              button_get_recovery_code
              button_restore_password
              by_sms_code
              by_email
              can_not_login_text
              email_placeholder
              email_title
              keep_me_logged_in_text
              login_title
              password_title
              privacy_policy_text
              personal_data_checkbox_title
              registration_title
              phone_title
              variant_remember_phone
              variant_remember_email
              variant_remember_client_code
              variant_remember_login
              variant_send_code_to_phone
              variant_send_code_to_email
              code_title
              code_placeholder
              code_sent_on_phone
              code_sent_on_email
              sms_code_title
              sms_code_placeholder
              enter_login
              enter_code
              create_new_passwords
              create_new_passwords_button
              new_password
              repeat_new_password
              incorect_login_or_password
              user_already_exists
              incorrect_data_on_restoring
              incorrect_data_on_register
              restore_success
              passwords_do_not_match
              code_is_incorrect
              login_processing
              register_processing
              client_not_founded
              accept_the_terms
              repeat_password_title
              error_occured
            }
          }
          ... on PrismicPageDataBodyTextWithColoredButtons {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              big_title {
                html
              }
              description_rich {
                html
              }
              button_text
              button_link
              link_text
              link_url
            }
            items {
              button_title
              button_subtitle
              button_link
              button_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodySiteGuide {
            id
            slice_type

            items {
              group_type
              group_title
              group_link_title
              group_link_url
              link_1_title
              link_1_url
              link_1_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_2_title
              link_2_url
              link_2_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_3_title
              link_3_url
              link_3_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_4_title
              link_4_url
              link_4_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_5_title
              link_5_url
              link_5_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyOnlyText {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              align
              text {
                html
              }
              button_text
              button_link
            }
            items {
              card_text {
                html
              }
              card_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTextWithList {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              align
              text {
                html
              }
            }
            items {
              list_item_text {
                html
              }
              button_text
              button_link
            }
          }
          ... on PrismicPageDataBodyCardSet {
            id
            slice_type
            items {
              accent
              text {
                html
              }
              link_text
              link_url
              button_text
              button_link
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyCardSetV2 {
            id
            slice_type
            items {
              text {
                html
              }
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTextWithPictureOnTheSide {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              content_on_the_left
              text {
                html
              }
              button_text
              button_link
              link_text
              link_url
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              excerption_description
              excerption_title
            }
          }
          ... on PrismicPageDataBodyTextWithPictureAndList {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              content_on_the_left
              text {
                html
              }
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              list_item_text {
                html
                text
              }
              list_item_img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyBlockListTextWithPicture {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              text {
                html
              }
              button_text
              button_link
              link_text
              link_url
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTariffCards {
            id
            slice_type
            primary {
              text {
                html
              }
            }
            items {
              subtitle
              title
              description
              left_column {
                html
              }
              right_column {
                html
              }
              link_text
              link_url
              button_text
              button_link
              background {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyAccountOpeningForm {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              text {
                html
              }
              first_name
              first_name_placeholder
              last_name
              last_name_placeholder
              email_title
              email_placeholder
              phone_title
              phone_placeholder
              password_title
              password_placeholder
              button_text
              link_text
              link_url
              fields_not_filled
              you_are_not_registred
              sending
              success
              repeat_password_title
              passwords_do_not_match
              error_occured
            }
          }
          ... on PrismicPageDataBodyAccountLinkForm {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            	image_right {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              text {
                html
              }
              button_text
              button_url
            }
          }
          ... on PrismicPageDataBodySendMessageForm {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              text {
                html
              }
              name_title
              name_placeholder
              last_name_title
              last_name_placeholder
              email_title
              email_placeholder
              phone_title
              phone_placeholder
              button_text
              notification_text
              modal_button_text
            }
          }
          ... on PrismicPageDataBodyAccordion {
            id
            slice_type
            primary {
              text {
                html
              }
            }
            items {
              accordion_title
              accordion_description {
                html
              }
              button_text
              button_link
            }
          }
          ... on PrismicPageDataBodyTextAndTwoCardsWithText {
            id
            slice_type
            primary {
              align
              title_text {
                html
              }
            }
            items {
              align
              card_text {
                html
              }
              button_text
              button_link
            }
          }
          ... on PrismicPageDataBodyCardsIpo {
            id
            slice_type
            primary {
              check_type
              check_status
              link_text
              button_text
              button_link
              bottom_button_type
              bottom_button_text
              bottom_button_link
            }
            items {
              document_ipo {
                document {
                  ... on PrismicIpo {
                    uid
                    data {
                      title
                      type
                      status
                      logo {
                        alt
                        localFile {
                          extension
                          publicURL
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                      text_expected_pre_ipo {
                        html
                      }
                      text_expected_ipo {
                        html
                      }
                      text_actively_pre_ipo {
                        html
                      }
                      text_actively_ipo {
                        html
                      }
                      result_title_pre_ipo
                      result_pre_ipo
                      text_passed_pre_ipo {
                        html
                      }
                      result_title_ipo
                      result_ipo
                      text_passed_ipo {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTariffCardsFromDocuments {
            id
            slice_type
            items {
              link_text
              link_url
              button_text
              button_link
              tariff {
                document {
                  ... on PrismicTariff {
                    id
                    uid
                    data {
                      subtitle
                      name
                      description {
                        html
                      }
                      body {
                        ... on PrismicTariffDataBodyHeader {
                          id
                          slice_type
                          primary {
                            header {
                              html
                            }
                          }
                        }
                        ... on PrismicTariffDataBodyGroupValue {
                          id
                          slice_type
                          primary {
                            group_type
                            group_title {
                              html
                            }
                          }
                          items {
                            regular_title {
                              html
                            }
                            regular_subtitle {
                              html
                            }
                            accent_color
                            accent_title {
                              html
                            }
                            accent_subtitle {
                              html
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTextAndTwoCardsTextAndList {
            id
            slice_type
            primary {
              text {
                html
              }
              align
              left_card_text {
                html
              }
              left_button_text
              left_button_link
              right_card_text {
                html
              }
              right_button_text
              right_button_link
              list_on_left
            }
            items {
              title
              value
            }
          }
          ... on PrismicPageDataBodyContacts {
            id
            slice_type
            primary {
              title
            }
            items {
              card_title
              card_description {
                html
              }
              image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              contact {
                html
              }
            }
          }
          ... on PrismicPageDataBodyIpoCatalog {
            id
            slice_type
            primary {
              check_type
              check_status
              link_text
              button_text
              button_link
            }
          }
          ... on PrismicPageDataBodyBreadcrumbs {
            id
            slice_type
            items {
              link_title
              link_url
            }
          }
          ... on PrismicPageDataBodyHeaderWithIndicators {
            id
            slice_type
            primary {
              title
              title_column_1
              value_column_1
              title_column_2
              value_column_2
              title_column_3
              value_column_3
              title_column_4
              value_column_4
              image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title_additional_column_1
              value_additional_column_1
              title_additional_column_2
              value_additional_column_2
              link_text
              link_url
              button_text
              button_link
            }
            items {
              tag
            }
          }
          ... on PrismicPageDataBodyNews {
            id
            slice_type
            items {
              accent
              key_text
              rich_text {
                html
                text
              }
              key_date
            }
            primary {
              main_title {
                html
                text
              }
            }
          }
          ... on PrismicPageDataBodyOurTeam {
            id
            slice_label
            slice_type
            primary {
              header_text {
                html
              }
              modal_characteristic_text
              modal_segments_text
            }
            items {
              team_member {
                document {
                  ... on PrismicTeamMember {
                    data {
                      body {
                        ... on PrismicTeamMemberDataBodyImage {
                          slice_type
                          items {
                            image {
                              gatsbyImageData
                            }
                          }
                        }
                        ... on PrismicTeamMemberDataBodyText {
                          slice_type
                          primary {
                            content
                          }
                        }
                      }
                      experience
                      name
                      photo {
                        gatsbyImageData
                      }
                      role
                      segments {
                        tags
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
